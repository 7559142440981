import React from 'react'
import '../css/iphone.css'
import screen1 from '../assets/screen1.png'

function IPhoneX() {
    return (
        <div className="iphone">
            <div className="iphonex-cover">
                <img src={screen1} alt=""></img>
            </div>
        </div>
    )
}

export default IPhoneX;