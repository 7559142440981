import React from 'react';
import Hero from './Hero';
import Analytics from './Analytics';
import Features from './Features';
import Contact from './Contact';
import Footer from './Footer';

function Home() {
    return (
      <div>
        <Hero/>
        <Analytics/>
        <Features/>
        <Contact id="contact"/>
        <Footer/>
      </div>
    )
}

export default Home;