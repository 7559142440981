import React from 'react'
import '../css/contact.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons'

function Contact() {
    return (
        <div className="container">
            <div className="contact">
                <h2 className="title title-contact is-3">Contactez-nous</h2>
{/*                 <div className="field">
                    <div className="control has-icon-left">
                        <input className="input" type="text" placeholder="Nom et prénom"></input>
                    </div>
                </div>
                <div className="field">
                    <div className="control has-icon-left">
                        <input className="input" type="email" placeholder="Email"></input>
                    </div>
                </div>
                <div className="field">
                    <div className="control has-icon-left">
                        <input className="input" type="text" placeholder="Sujet"></input>
                    </div>
                </div>
                <div className="field">
                    <div className="control has-icon-left">
                        <textarea className="textarea has-fixed-size" placeholder="Message"></textarea>
                    </div>
                </div>
                <span className="button has-icon-right" style={{backgroundImage: "linear-gradient(to right, #ec5445 0%, #d54d56 100%)", color: "white"}}>
                    <span>Envoyer</span>
                </span> */}
                <div className="">
                    <div className="">
                        <a href="https://www.facebook.com/corntime">
                            <FontAwesomeIcon className="icon is-medium has-text-grey" icon={faFacebook}/>
                        </a>
                        <a href="https://twitter.com/CornTimeApp">
                            <FontAwesomeIcon className="icon is-medium has-text-grey" icon={faTwitter}/>
                        </a>
                        <a href="https://www.linkedin.com/company/corntime/">
                            <FontAwesomeIcon className="icon is-medium has-text-grey" icon={faLinkedin}/>
                        </a>
                    </div>
                    <div className=" ">
                        <a className="button" href="https://www.buymeacoffee.com/thomasboda">
                            <span className="icon">
                                <FontAwesomeIcon icon={faCoffee} />
                            </span>
                            <span>Offrez-moi un café</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;