import React from 'react';
import '../css/home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAppStore as appStore, faGooglePlay as playStore} from '@fortawesome/free-brands-svg-icons'
import IPhoneX from './IPhoneX'

function Hero() {
    return (
        <div className="hero-bg">
            <div className="container">
                <div className="columns hero-ct is-desktop">
                    <div className="column">
                        <h1 className="title title-hero is-1">CornTime</h1>
                        <h4 className="subtitle">Votre gestionnaire cinéma</h4>
                        <div className="divider"/>
                        <div className="columns is-desktop">
                            On revient bientôt...
{/*                             <div className="column is-narrow">
                                <a className="button is-medium hero-button" href="https://apps.apple.com/fr/app/corntime/id1413728316">
                                    <span className="icon">
                                        <FontAwesomeIcon icon={appStore} />
                                    </span>
                                    <span>App Store</span>
                                </a>
                            </div>
                            <div className="column is-narrow">
                                <button className="button is-medium is-black hero-button" disabled>
                                    <span className="icon">
                                        <FontAwesomeIcon icon={playStore} />
                                    </span>
                                    <span>Play Store</span>
                                </button>
                                <br/>
                                (en développement)
                            </div> */}
                        </div>
                    </div>
                    <div className="column">
                        <IPhoneX />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero;