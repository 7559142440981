import React from 'react';

function Footer() {
    return (
        <div className="red-bg" style={{paddingTop: "50px", paddingBottom: "50px"}}>
            <div className="container">
                Copyright 2018-{new Date().getFullYear()}. CornTime
            </div>
        </div>
    )
}

export default Footer;