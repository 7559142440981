import React from 'react';
import '../css/analytics.css';
import "../css/index.css";
import { Cinema, Checklist, User } from '../assets';

function Analytics() {
    return (
        <div className="container" style={{paddingTop: "50px", paddingBottom: "100px"}}>
            <div style={{fontSize: "36px", lineHeight: "28px", textAlign: "center", color: "#4a4a4a"}}>
                <b>Quelques chiffres</b>
                <div className="bar black"></div>
            </div>
            <div className="columns is-desktop is-centered" style={{paddingTop: "20px"}}>
                <div className="column" style={{paddingRight: "15px", paddingLeft: "15px"}}>
                    <div className="container" style={{border: "1px solid", borderColor: "rgba(89, 96, 130, 0.1)", boxShadow: "0px 1px 5px 3px #f9f9f9", padding: "25px", maxWidth:"350px"}}>
                        <img src={Cinema} style={{maxHeight: "60px", marginBottom:"30px"}} alt="Cinéma"></img><br/>
                        <div style={{color: "#4a4a4a"}}>
                            <b style={{fontSize: "24px", lineHeight: "28px"}}>576 937</b><br/>
                            films disponibles sur l'application.
                        </div>
                    </div>
                </div>
                <div className="column" style={{paddingRight: "15px", paddingLeft: "15px"}}>
                    <div className="container" style={{border: "1px solid", borderColor: "rgba(89, 96, 130, 0.1)", boxShadow: "0px 1px 5px 3px #f9f9f9", padding: "25px", maxWidth:"350px"}}>
                        <img src={Checklist} style={{maxHeight: "60px", marginBottom:"30px"}} alt="Une liste"></img><br/>
                        <div style={{color: "#4a4a4a"}}>
                            <b style={{fontSize: "24px", lineHeight: "28px"}}>4 624</b><br/>
                            films ajoutés dans les listes des utilisateurs.
                        </div>
                    </div>
                </div>
                <div className="column" style={{paddingRight: "15px", paddingLeft: "15px"}}>
                    <div className="container" style={{border: "1px solid", borderColor: "rgba(89, 96, 130, 0.1)", boxShadow: "0px 1px 5px 3px #f9f9f9", padding: "25px", maxWidth:"350px"}}>
                        <img src={User} style={{maxHeight: "60px", marginBottom:"30px"}} alt="Un utilisateur"></img><br/>
                        <div style={{color: "#4a4a4a"}}>
                            <b style={{fontSize: "24px", lineHeight: "28px"}}>688</b><br/>
                            utilisateurs déjà inscrits sur CornTime.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Analytics;