import React from 'react';
import "../css/index.css"

function Features() {
    return (
        <div className="red-bg features">
            <div className="container">     
                <div style={{fontSize: "36px", lineHeight: "28px", textAlign: "center"}}>
                    <b>Fonctionnalités</b>
                    <div className="bar"></div>
                </div>
                <div className="columns is-desktop is-centered">
                • Lister l'ensemble des films que vous avez vu et que vous voulez voir<br/>
                • Synchroniser vos listes entre vos différents appareils<br/>
                • Accéder aux différentes informations de l'intégralité des films existant dans le monde<br/>
                • Obtenir la liste des différents films actuellement au cinéma, prochainement au cinéma, mais également les films populaires
                </div>
            </div>
        </div>
    )
}

export default Features;