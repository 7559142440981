import React from 'react';
import './css/index.css';
import NavigationMenu from './components/NavMenu';

function App() {
	return (
		<div>
			<NavigationMenu />
		</div>
  );
}

export default App;
