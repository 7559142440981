import React from 'react';
import '../css/navbar.css'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from './Home';

function NavigationMenu() {
    return (
      <Router>
        <Route path="/" exact component={Home}/>
        <nav className="navbar is-transparent">
          <div className="container">
            <p className="navbar-item navbar-title navbar-brand">
              <strong style={{color: "white"}}>CornTime</strong>
            </p>
            <div className="navbar-menu navbar-start">
              <div className="navbar-end navbar-item field is-grouped">
                <p className="control">
                  {/* <a className="navbar-item" href="/">
                    CONTACT
                  </a> */}
                </p>
              </div>
            </div>
          </div>
        </nav>
      </Router>
    )
}

export default NavigationMenu;